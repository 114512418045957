import React, {Fragment, useRef} from 'react';
import {useDispatch} from "react-redux";
import {mapSetMapImage} from "../../store/map/mapAction";
import * as pdfjsLib from 'pdfjs-dist/webpack';

const MapImageUpload = () => {

    const dispatch = useDispatch();

    const inputRef = useRef();
    const canvasRef = useRef();

    const handleFileSelect = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const fileType = file.type;

            if (fileType === 'application/pdf') {
                const fileReader = new FileReader();
                fileReader.onload = async () => {
                    const arrayBuffer = fileReader.result;
                    const pdf = await pdfjsLib.getDocument(arrayBuffer).promise;
                    const page = await pdf.getPage(1);
                    const viewport = page.getViewport({scale: 1});
                    const canvas = canvasRef.current;
                    const context = canvas.getContext('2d');
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;
                    await page.render({canvasContext: context, viewport});
                    await new Promise(resolve => window.requestAnimationFrame(resolve));
                    setTimeout(() => {
                        const imageData = canvas.toDataURL('image/jpeg', 1.0);
                        dispatch(mapSetMapImage(imageData));
                    }, 100);
                }
                fileReader.readAsArrayBuffer(file);
            } else {
                const imageUrl = URL.createObjectURL(file);
                dispatch(mapSetMapImage(imageUrl));
            }
        }
    }

    return (
        <Fragment>
            <button type="button" className="btn btn-primary" onClick={() => inputRef.current?.click()}>
                Kartenhintergrund auswählen
            </button>
            <input type="file" className="d-none" onChange={handleFileSelect} ref={inputRef}/>
            <canvas ref={canvasRef} style={{position: 'absolute', left: -5000}}></canvas>
        </Fragment>
    )
}

export default MapImageUpload;