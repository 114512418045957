import React from 'react';
import {useSelector} from "react-redux";
import {SECU_TYPE_34A, SECU_TYPE_STEWARD} from "../../config/secuType";
import {ButtonGroup, Container, DropdownButton} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartPie} from "@fortawesome/free-solid-svg-icons";

const Summary = () => {

    const secuTypes = [SECU_TYPE_34A, SECU_TYPE_STEWARD];

    const mapStore = useSelector(state => state.map);

    return (
        <div className="Summary">
            <DropdownButton title={<FontAwesomeIcon icon={faChartPie} />} as={ButtonGroup} variant="primary" noCloseOnEsc drop="start">
                <DropdownItem onClick={(e) => e.stopPropagation()} as={Container}>
                    <h6>
                        Auswertung
                    </h6>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th/>
                            {secuTypes.map((st, stI) => (
                                <th key={stI}>
                                    {st.replace('SECU_TYPE_' , '')}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {mapStore.layers.map((layer, lI) => (
                            <tr key={lI}>
                                <td>
                                    {layer.name}
                                </td>
                                {secuTypes.map((st, stI) => (
                                    <td key={stI}>
                                        {mapStore.markers.filter(m => m.secuType === st && m.layer === layer.id)?.length}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                        <tfoot>
                        <tr>
                            <th>
                                Gesamt
                            </th>
                            {secuTypes.map((st, stI) => (
                                <th key={stI}>
                                    {mapStore.markers.filter(m => m.secuType === st)?.length}
                                </th>
                            ))}
                        </tr>
                        </tfoot>
                    </table>
                </DropdownItem>
            </DropdownButton>
        </div>
    )
}

export default Summary;