import React, {Fragment, useEffect, useState} from 'react';
import {ImageOverlay, MapContainer, Marker, Popup} from "react-leaflet";
import L from 'leaflet';
import {useDispatch, useSelector} from "react-redux";
import MapEventHandler from "./MapEventHandler";
import MarkerClusterGroup from "react-leaflet-cluster";
import {v4 as uuidv4} from "uuid";
import MapPrint from "./MapPrint";
import {SECU_TYPE_34A, SECU_TYPE_STEWARD} from "../../config/secuType";
import {mapMarkerSetLayer, mapMarkerSetSecuType} from "../../store/map/mapAction";

const Map = () => {

    const dispatch = useDispatch();
    const mapStore = useSelector(state => state.map);

    const [mapImageInfo, setMapImageInfo] = useState(null);

    useEffect(() => {
        if (mapStore.mapImage) {
            const img = new Image();
            img.src = mapStore.mapImage;

            img.onload = () => {
                setMapImageInfo({
                    width: img.width,
                    height: img.height,
                    ratio: img.width / img.height
                });
            }
        }
    }, [mapStore.mapImage])

    return mapStore.mapImage && mapImageInfo && (
        <div className="Map">
            <MapContainer bounds={[[0, 0], [1000, 1000 * mapImageInfo.ratio]]} crs={L.CRS.Simple} maxZoom={12} attributionControl={false}>
                <ImageOverlay url={mapStore.mapImage} bounds={[[0, 0], [1000, 1000 * mapImageInfo.ratio]]}/>
                <MapEventHandler/>
                <MarkerClusterGroup chunkedLoading key={uuidv4()}>
                    {mapStore.markers.filter(m => mapStore.layers?.find(l => l.id === m.layer)?.visible)?.map((marker, mI) => (
                        <Marker position={marker.position} key={mI} icon={L.divIcon({
                            className: `custom-marker marker-secu-type-${marker.secuType.replace('SECU_TYPE_', '')}`,
                            html: ''
                        })}>
                            <Popup>
                                <select value={marker.secuType} onChange={(e) => dispatch(mapMarkerSetSecuType(marker.id, e.target.value))} className="form-control">
                                    {[SECU_TYPE_34A, SECU_TYPE_STEWARD].map((type, tI) => (
                                        <option value={type} key={tI}>
                                            {type.replace('SECU_TYPE_', '')}
                                        </option>
                                    ))}
                                </select>
                                <select value={marker.layer} onChange={(e) => dispatch(mapMarkerSetLayer(marker.id, e.target.value))} className="form-control">
                                    {mapStore.layers.map((layer, lI) => (
                                        <option value={layer.id} key={lI}>
                                            {layer.name}
                                        </option>
                                    ))}
                                </select>
                            </Popup>
                        </Marker>
                    ))}
                </MarkerClusterGroup>
                <MapPrint position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={false} title="Print" />
                <MapPrint position="topleft" sizeModes={['Current', 'A4Portrait', 'A4Landscape']} hideControlContainer={true} title="Export as PNG" exportOnly/>
            </MapContainer>
        </div>
    )
}

export default Map;