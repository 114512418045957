import React from 'react';
import '../theme/style.scss';
import Map from "../elements/Map";
import {useSelector} from "react-redux";
import Layers from "../elements/Map/Layers";
import Summary from "../elements/Map/Summary";
import Footer from "../elements/Footer";
import TopNav from "../elements/TopNav";
import ProjectSelector from "../elements/ProjectSelector";

const App = () => {

    const mapStore = useSelector(state => state.map);

    return (
        <div className="App d-flex flex-column">
            <TopNav/>
            <div className="content-container flex-grow-1 d-flex align-items-center justify-content-center flex-column">
                {!mapStore?.mapImage ? (
                    <ProjectSelector/>
                ) : (
                    <Map/>
                )}
            </div>
            <Footer/>
            {mapStore?.mapImage && <Layers/>}
            {mapStore?.mapImage && <Summary/>}
        </div>
    )
}

export default App;