import React from 'react';
import packageJson from '../../../package.json';

const Footer = () => {
    return (
        <div className="Footer bg-dark text-light py-2">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <small>Anton Version {packageJson.version}</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;