import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {mapSetClickAction} from "../../store/map/mapAction";
import {Button, ButtonGroup, Container, Nav, Navbar} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHand, faLocationDot, faPlus} from "@fortawesome/free-solid-svg-icons";

const TopNav = () => {
    const dispatch = useDispatch();

    const mapStore = useSelector(state => state.map);

    const handleSetClickAction = (clickAction) => {
        dispatch(mapSetClickAction(mapStore.clickAction !== clickAction ? clickAction : null))
    }

    return (
        <Navbar expand="md" bg="primary" variant="dark">
            <Container fluid={true}>
                <Navbar.Brand>
                    ANTON
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="main-navbar" />
                <Navbar.Collapse id="main-navbar">
                    <Nav className="me-auto ms-5">
                        <ButtonGroup>
                            <Button variant={mapStore.clickAction !== 'createMarker' ? "info" : "outline-warning"} className="px-3" title="Bewegen, Auswahl"  onClick={() => handleSetClickAction(null)}>
                                <FontAwesomeIcon icon={faHand} />
                            </Button>
                            <Button variant={mapStore.clickAction === 'createMarker' ? "warning" : "outline-info"} className="ps-3" title="Punkt hinzufügen"  onClick={() => handleSetClickAction('createMarker')}>
                                <FontAwesomeIcon icon={faLocationDot} />
                                <FontAwesomeIcon icon={faPlus} size="xs" className="pb-1"/>
                            </Button>
                        </ButtonGroup>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default TopNav;