import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useMapEvents} from "react-leaflet";
import {mapAddMarker} from "../../store/map/mapAction";
import {v4 as uuidv4} from "uuid";

const MapEventHandler = () => {
    const dispatch = useDispatch();
    const mapStore = useSelector(state => state.map);

    const map = useMapEvents({
        click(e) {
            if (mapStore.clickAction === 'createMarker') {
                dispatch(mapAddMarker({
                    id: uuidv4(),
                    position: e.latlng,
                }));
            }
        }
    })

    return null;
}

export default MapEventHandler;