import React, {Fragment, useState} from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faPlus} from "@fortawesome/free-solid-svg-icons";
import MapImageUpload from "../data/MapImageUpload";

const ProjectSelector = () => {

    const [step, setStep] = useState(null);

    return (
        <div>
            {!step && (
                <Fragment>
                    <Button onClick={() => setStep('createProject')} variant="primary" className="px-5 py-4 fs-4 mx-3">
                        <FontAwesomeIcon icon={faPlus} size="xl"/><br/><br/>
                        Projekt<br/>erstellen
                    </Button>
                    <Button onClick={() => setStep('loadProject')} variant="info" className="px-5 py-4 fs-4 mx-3">
                        <FontAwesomeIcon icon={faFile} size="xl"/><br/><br/>
                        Projekt<br/>laden
                    </Button>
                </Fragment>
            )}
            {step === 'createProject' && (
                <div>
                    <h2>
                        Projekt erstellen
                    </h2>
                    <p>
                        Bitte wähle einen Kartenhintergrund aus, um ein neues Projekt zu erstellen.
                    </p>
                    <MapImageUpload/>
                    <Button variant="link" onClick={() => setStep(null)}>
                        abbrechen
                    </Button>
                </div>
            )}
            {step === 'loadProject' && (
                <div>
                    <h2>
                        Projekt laden
                    </h2>
                    <p>
                        Bitte wähle eine Projektdatei aus, um sie zu laden.
                    </p>
                    <Button variant="link" onClick={() => setStep(null)}>
                        abbrechen
                    </Button>
                </div>
            )}
        </div>
    )
}

export default ProjectSelector;