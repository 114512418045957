import {
    MAP_ADD_LAYER,
    MAP_ADD_MARKER, MAP_LAYER_SET_VISIBLE,
    MAP_MARKER_SET_LAYER,
    MAP_MARKER_SET_SECU_TYPE,
    MAP_SET_CLICK_ACTION,
    MAP_SET_MAP_IMAGE
} from "../../config/actions";
import {SECU_TYPE_34A} from "../../config/secuType";

export const mapSetMapImage = (mapImage) => {
    return {
        type: MAP_SET_MAP_IMAGE,
        mapImage
    };
};

export const mapSetClickAction = (clickAction) => {
    return {
        type: MAP_SET_CLICK_ACTION,
        clickAction
    };
};

export const mapAddMarker = (marker) => {
    return {
        type: MAP_ADD_MARKER,
        marker
    };
};

export const mapMarkerSetSecuType = (id, secuType) => {
    return {
        type: MAP_MARKER_SET_SECU_TYPE,
        id,
        secuType
    };
};

export const mapMarkerSetLayer = (id, layer) => {
    return {
        type: MAP_MARKER_SET_LAYER,
        id,
        layer
    };
};

export const mapLayerSetVisible = (id, visible) => {
    return {
        type: MAP_LAYER_SET_VISIBLE,
        id,
        visible
    };
};

export const mapAddLayer = (name) => {
    return {
        type: MAP_ADD_LAYER,
        name
    };
};