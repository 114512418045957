import {
    MAP_ADD_LAYER,
    MAP_ADD_MARKER, MAP_LAYER_SET_VISIBLE,
    MAP_MARKER_SET_LAYER,
    MAP_MARKER_SET_SECU_TYPE,
    MAP_SET_CLICK_ACTION,
    MAP_SET_MAP_IMAGE
} from "../../config/actions";
import {v4 as uuidv4} from "uuid";
import {SECU_TYPE_34A} from "../../config/secuType";

const defaultState = {
    mapImage: null,
    clickAction: null,
    markers: [],
    layers: [{
        id: uuidv4(),
        name: 'Standard',
        visible: true
    }]
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case MAP_SET_MAP_IMAGE:
            return {
                ...state,
                mapImage: action.mapImage
            };
        case MAP_SET_CLICK_ACTION:
            return {
                ...state,
                clickAction: action.clickAction
            };
        case MAP_ADD_MARKER:
            return {
                ...state,
                markers: [...state.markers, {
                    ...action.marker,
                    secuType: SECU_TYPE_34A,
                    layer: state.layers[0]?.id
                }]
            };
        case MAP_MARKER_SET_SECU_TYPE:
            return {
                ...state,
                markers: state.markers.map(marker => {
                    if (marker.id === action.id) {
                        return {
                            ...marker,
                            secuType: action.secuType
                        }
                    } else {
                        return marker;
                    }
                })
            }
        case MAP_MARKER_SET_LAYER:
            return {
                ...state,
                markers: state.markers.map(marker => {
                    if (marker.id === action.id) {
                        return {
                            ...marker,
                            layer: action.layer
                        }
                    } else {
                        return marker;
                    }
                })
            }
        case MAP_LAYER_SET_VISIBLE:
            return {
                ...state,
                layers: state.layers.map(layer => {
                    if (layer.id === action.id) {
                        return {
                            ...layer,
                            visible: action.visible
                        }
                    } else {
                        return layer;
                    }
                })
            }
        case MAP_ADD_LAYER:
            return {
                ...state,
                layers: [...state.layers, {
                    id: uuidv4(),
                    name: action.name,
                    visible: true
                }]
            }
        default:
            return state;
    }
};