import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {mapAddLayer, mapLayerSetVisible} from "../../store/map/mapAction";
import {ButtonGroup, Container, Dropdown, DropdownButton} from "react-bootstrap";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Layers = () => {

    const dispatch = useDispatch();
    const mapStore = useSelector(state => state.map);

    const [newLayerName, setNewLayerName] = useState('');

    const handleAddLayer = () => {
        dispatch(mapAddLayer(newLayerName));
        setNewLayerName('');
    }

    return (
        <div className="Layers">
            <DropdownButton title={<FontAwesomeIcon icon={faLayerGroup} />}
                            as={ButtonGroup} variant="primary" noCloseOnEsc drop="start">
                <Dropdown.Item onClick={(e) => e.stopPropagation()} as={Container}>
                    <h6>Layer</h6>
                    {mapStore.layers.map((layer, lI) => (
                        <div className="border px-3 py-2" key={lI}>
                            <input type="checkbox" checked={layer.visible} onChange={() => dispatch(mapLayerSetVisible(layer.id, !layer.visible))} className="me-2 d-inline-block"/>
                            <span className={!layer.visible ? 'text-secondary' : 'text-primary'}>
                    {layer.name}
                </span>
                        </div>
                    ))}
                    <div className="mt-4">
                        <b>Layer hinzufügen</b><br/>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Name" value={newLayerName} onChange={(e) => setNewLayerName(e.target.value)}/>
                            <button type="button" className="btn btn-primary" onClick={handleAddLayer}>
                                Speichern
                            </button>
                        </div>
                    </div>
                </Dropdown.Item>
            </DropdownButton>
        </div>
    )
}

export default Layers;